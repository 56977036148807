
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import { AuthStoreActions, AUTH_STORE_NAME } from '@/store/auth.store';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    LogoComponent: () => import(
        /* webpackChunkName: "LogoComponent" */
        '@/components/Logo.component.vue'
        ),
  },
  mixins: [validationMixin],
  validations: {
    emailCopy: { email }
  }
})
export default class PasswordRequestComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * Initial Email Address
   */
  @Prop()
  public email?: string;

  /**
   * Password Reset Action
   * @private
   */
  @AuthStore.Action(AuthStoreActions.INITIATE_PASSWORD_RESET)
  private initiatePasswordResetAction!: (email: string) => Promise<any>;

  /**
   * Private Email field used for validation
   * @private
   */
  private emailCopy: string = '';

  /**
   * Valid and Loading State's
   * @private
   */
  private isValid: boolean = false;
  private isLoading: boolean = false;

  /**
   * Successful Sent State bool
   * Shows / Hides the Email field
   * and
   * @private
   */
  private successful: boolean = false;

  @Watch('email', { immediate: true })
  private onEmailChanged() {
    if (this.email) {
      this.emailCopy = this.email;
      this.isValid = !this.$v!.$invalid;
    }
  }

  /**
   * Dismiss Event Sender
   * For closing the Dialog on Button press
   * @private
   */
  private dismiss() {
    // Reset Success state
    this.successful = false;

    // Reset form and send close event
    this.$v.$reset();
    this.$emit("close");
  }

  /**
   * Method for Requesting a Password Reset Email
   * @private
   */
  private async requestPassword() {
    this.isValid = !this.$v!.$invalid;

    // Return if invalid or is still loading
    if (!this.isValid && this.isLoading) {
      return;
    }

    // Try to reset the password with the current email
    try {
      this.isLoading = true;

      // Initiate Password Reset
      await this.initiatePasswordResetAction(this.emailCopy);

      // Show Success Message
      this.successful = true;
    } catch (e) {
      // Not Successful
      this.successful = false;

      // Handle any incoming errors
      this.$handleError(e, () => {
        switch (e.status) {
          case 403:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.PASSWORD_RESET_INVALID_ACCOUNT');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_ERROR');
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  private checkForm(type: string): boolean {
    this.isValid = !this.$v!.$invalid;
    return this.triggerValidation(type);
  }
}
